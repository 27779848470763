<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-27 15:53:21
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-04 15:26:31
-->
<template>
  <el-dialog
    title="交付任务"
    :visible.sync="isShow"
    width="610px"
    :close-on-click-modal="false"
    :before-close="handleClose"
    :destroy-on-close="true"
  >
    <el-form :model="taskForm" :rules="taskFormRules" ref="taskForm" label-width="100px">
      <el-form-item label="交付说明" prop="submitRemark">
        <el-input
          type="textarea"
          maxlength="100"
          show-word-limit
          v-model="taskForm.submitRemark"
          :disabled="fileList.length>0"
          :autosize="{ minRows: 4, maxRows: 8}"
        ></el-input>
      </el-form-item>
      <el-form-item label="上传凭证" style="margin-top:32px">
        <upload-file
          :readOnly="fileList.length>0"
          :fileList="fileListTemp"
          subUrl="task-order"
          :limit="fileListTemp.length?fileListTemp.length:5"
          @setFile="handleChange($event)"
        ></upload-file>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" v-if="fileList.length==0">
      <el-button type="primary" :loading="submitLoading" @click="submitForm('taskForm')">提交</el-button>
    </div>
  </el-dialog>
</template>
<script>
import uploadFile from "@/components/upload/index.vue";
import api from "@/api/list";
export default {
  components: {
    uploadFile,
  },
  props: {
    isShowDeliver: {
      type: Boolean,
      default: false,
    },
    desc: {
      type: String,
      default: "",
    },
    fileList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    fileListTemp: {
      get: function () {
        return this.fileList;
      },
      set: function (v) {},
    },
    isShow: {
      get: function () {
        return this.isShowDeliver;
      },
      set: function (v) {
        this.$emit("refresh", v);
      },
    },
  },
  watch: {
    fileList: {
      handler: function (v) {
        this.fileListTemp = v;
      },
      immediate: true,
      deep: true,
    },
    desc: {
      handler: function (v) {
        this.taskForm.submitRemark = v;
      },
      immediate: true,
    },
  },
  data() {
    return {
      submitLoading: false,
      taskForm: {
        taskId: this.$route.query.taskId,
        submitRemark: this.desc,
        submitResult: "",
      },
      taskFormRules: {
        submitRemark: [
          { required: true, message: "请输入交付说明", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleClose(done) {
      done();
    },
    handleChange(file) {
      let [...temp] = file;

      temp.forEach((item, index) => {
        temp[index] = item.replace(process.env.VUE_APP_FILE_PATH, "");
      });
      this.taskForm.submitResult = temp.toString();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          api.addTaskSubmit(this.taskForm, { method: "post" }).then((res) => {
            this.submitLoading = false;
            if (res.status == 200) {
              this.$message({
                showClose: true,
                message: "交付成功",
                type: "success",
              });
              this.isShow = false;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>