<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-19 14:31:39
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-10-15 18:21:54
-->
<template>
  <div class="taskDetailPage clearfix">
    <div class="left_section">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: './tasks' }">我的任务</el-breadcrumb-item>
            <el-breadcrumb-item>任务详情</el-breadcrumb-item>
          </el-breadcrumb>
          <el-link type="warning" style="float: right" :underline="false" v-if="content.employeeTaskStatus == 'applying'">申请中</el-link>
          <el-link type="success" style="float: right" :underline="false" v-else-if="content.employeeTaskStatus == 'doing'">进行中</el-link>
          <el-link type="success" style="float: right" :underline="false" v-else-if="content.employeeTaskStatus == 'finish'">已完成</el-link>
          <el-link type="danger" style="float: right" :underline="false" v-else-if="content.employeeTaskStatus == 'nopass'">未通过</el-link>
          <el-link type="info" style="float: right" :underline="false" v-else-if="content.employeeTaskStatus == 'cancelled'">已作废</el-link>
        </div>
        <h2>
          <p class="title text-ellipsis">{{content.ecompanyJobCategoryName}}</p>
          <p class="money"> {{content.taskMoney=="费用面议"?"":content.amount}} <span :class="{only:content.amount==undefined||content.amount.length == 0||content.taskMoney == '费用面议'}">
              <template v-if="content.taskMoney == '费用面议'">费用面议</template>
              <template v-else-if="content.taskMoney == '固定价格'">/元</template>
              <template v-else-if="content.taskMoney == '按月'">元/月</template>
              <template v-else-if="content.taskMoney == '按周'">元/周</template>
              <template v-else-if="content.taskMoney == '按天'">元/天</template>
              <template v-else-if="content.taskMoney == '按时'">元/小时</template>
            </span>
          </p>
        </h2>
        <h3 class="text-ellipsis">{{content.taskName}}</h3>
        <p v-if="content.settlement">结算方式：{{content.settlement}}</p>
        <p>发布时间：{{content.publishDate}}</p>
        <p>申请时间：{{content.applyDate}}</p>
        <p v-if="content.finishDate">完成时间：{{content.finishDate}}</p>
        <p> 截止时间： <template v-if="content.lastEndDate == undefined">长期有效</template>
          <template v-else-if="(timeLeftCount(content.lastApplyDate) <= 0) || (content.applyStatus == 'stop')">已截止</template>
          <template v-else>{{content.lastEndDate}}</template>
        </p>
        <p>地 &nbsp; 区：{{content.cityName?content.cityName:"不限"}}</p>
        <p>发&nbsp;布&nbsp;方：{{content.ecompanyName}}</p>
        <p> 协议状态： <template v-if="content.contractStatus == 'none'">无协议</template>
          <template v-else-if="content.contractStatus == 'wsign'"> 待签署 
            <el-button 
            type="primary" 
            size="mini" 
            style="margin-left:10px" 
            @click="toSign(content)">
            去签署</el-button>
          </template>
          <template v-else>
            <template v-if="content.contractStatus == 'signed'">已签署</template>
            <template v-else-if="content.contractStatus == 'termination'">已终止</template>
            <template v-else-if="content.contractStatus == 'cancelled'">已作废</template>
            <template v-else-if="content.contractStatus == 'unifiedSignSuccess'">企业统签</template>
            <template v-else-if="content.contractStatus == 'unifiedSignFailure'">企业统签失败</template>
            <el-button type="success" size="mini" style="margin-left:10px" @click="$router.push({path:'/userCenter/contracts'})">查看协议</el-button>
          </template>
        </p>
      </el-card>
      <el-card class="box-card" header="任务详情" shadow="never">
        <div class="content" v-html="content.taskRemark"></div>
      </el-card>
      <el-card class="box-card" header="交付记录" shadow="never">
        <el-table v-if="deliveryList.length" :data="deliveryList" v-loading="listLoading" style="width: 100%" stripe>
          <el-table-column prop="createDate" label="交付时间"></el-table-column>
          <el-table-column prop="submitRemark" label="成果描述" show-overflow-tooltip></el-table-column>
          <el-table-column label="附件">
            <template slot-scope="scope">
              <el-link v-if="scope.row.submitResultList&&scope.row.submitResultList.length" type="primary" @click="filePreview(scope.row.submitResultList,scope.row.submitRemark)">查看</el-link>
              <span v-else>无</span>
            </template>
          </el-table-column>
        </el-table>
        <template v-else>
          <empty text="您还没有交付记录"></empty>
        </template>
        <pagination :listLoading="listLoading" @getList="getDeliveryList" @setLimit="form.size = $event" @setPage="form.current = $event" :currentPage="form.current" :pageSize="form.size" :total="form.total"></pagination>
        <div class="submitBox" v-if="content.employeeTaskStatus == 'doing' && content.isUploadResult === true">
          <el-button type="primary" v-if="content.taskStatus == 'doing' && (content.contractStatus == 'signed' || content.contractStatus == 'unifiedSignSuccess')" @click="isShowDeliver = true;desc='';fileList=[]">交付任务</el-button>
          <el-button disabled v-if="content.contractStatus == 'termination' || content.contractStatus == 'wsign' || content.contractStatus == 'cancelled' || content.contractStatus == 'unifiedSignSuccess'">协议未签署，不可交付任务</el-button>
        </div>
      </el-card>
      <deliverTask :isShowDeliver="isShowDeliver" :fileList="fileList" :desc="desc" @refresh="getDeliveryList(); isShowDeliver = false" />
    </div>
    <taskRight />
    <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    width="30%"
    center>
      <div class="dialog">
        <span class="dialog-text">请先用微信扫描下方二维码，关注【工蚁宝】微信公众号完成个体户认证与注册</span>
        <el-image 
        class="dialog-img"
        :src="url">
      </el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/list";
import {
  itemStatusHttpAction,
} from "@/api/status";
import taskRight from "./components/taskRight.vue";
import empty from "@/components/empty/index.vue";
import deliverTask from "../userCenter/components/deliverTask.vue";
import pagination from "@/components/pagination/index.vue";
export default {
  name: "taskDetail",
  components: {
    empty,
    deliverTask,
    taskRight,
    pagination,
  },
  data() {
    return {
      fileList: [],
      desc: "", //预览交付说明
      isShowDeliver: false,
      content: {},
      deliveryList: [],
      listLoading: true,
      dialogVisible:false,
      url: require('@/assets/header_qrcode.jpg'),
      form: {
        size: 10,
        current: 1,
        total: 0,
        taskId: this.$route.query.taskId,
      },
    };
  },
  mounted() {
    this.getDetail();
    this.getDeliveryList();
  },
  methods: {
    filePreview(fileList, desc) {
      this.isShowDeliver = true;
      this.fileList = fileList;
      this.desc = desc;
    },
    toSign(item){
      console.log('item',item);
      api.getRelationIndividual({ id: item.ocompanyId }, { method: "get" })
      .then((res) => {
        console.log('getRelationIndividual',res);
        if (res.status == 200) {
          if(!res.data){
            // 自由职业者人力公司，跳转至灵工签署页面
            this.$router.push({path:'/userCenter/signContract',query: {contractId: item.contractEmployeeId}})
          }else{
            // 个体户人力公司，跳转至个体户签署页面，跳转至个体户签署页面时，需要判断当前关联的个体户是否注册成功
            itemStatusHttpAction(item.ocompanyId).then((res) => {
              console.log('itemStatusHttpAction',res)
              if (res.status == "200"&&res.data) {
                // 成功：跳转至个体户签署页面
                this.$router.push({path:'/userCenter/signContractIndividual',query: {contractId: item.contractEmployeeId,ocompanyId: item.ocompanyId  }})
              }else {
                // 提示去微信认证
                this.dialogVisible = true;
              }
            });
          }
        }
      });
    },
    getDeliveryList() {
      api.getSubmitByEmployee(this.form).then((res) => {
        this.listLoading = false;
        this.deliveryList = res.data.records.map((v) => {
          if (v.submitResult) {
            let arr = v.submitResult.split(",");
            arr.forEach((item, index) => {
              arr[index] = process.env.VUE_APP_FILE_PATH + item;
            });
            v.submitResultList = arr;
          }
          return v;
        });
        this.total = res.data.total;
      });
    },
    getDetail() {
      api.getMyTaskDetail({ taskId: this.$route.query.taskId }).then((res) => {
        this.content = res.data.task;
      });
    },
    handleApplyTasks() {
      api
        .applyTasks({ taskId: this.$route.query.taskId }, { method: "post" })
        .then((res) => {
          if (res.data.status == 200) {
            this.$message({
              showClose: true,
              message: "申请成功",
              type: "success",
            });
            this.getDetail();
          } else {
            this.$message({
              showClose: true,
              message: res.data.message,
              type: "warning",
            });
          }
        });
    },
    timeLeftCount(data) {
      return new Date(data.replace(/-/g, "/")).getTime() - new Date().getTime();
    },
  },
};
</script>

<style lang="less" scoped>
.taskDetailPage {
  width: 1210px;
  margin: 20px auto;
  .left_section {
    float: left;
    width: 905px;
  }
}
.image_preview {
  position: relative;
  /deep/ .el-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    & > img {
      opacity: 0;
    }
  }
}
.box-card {
  position: relative;
  font-size: 14px;
  color: #999;
  /deep/ .el-table {
    .cell {
      text-align: center;
    }

    td,
    th {
      padding: 4px 0;
    }

    thead th {
      background: #8f9aee;
      color: #fff;
    }

    &.el-table--striped .el-table__body tr.el-table__row--striped td {
      background: #eff0f6;
    }

    .el-link {
      margin: 0 10px;
    }
  }

  /deep/ .el-card__body {
    position: relative;
    padding-bottom: 20px;
  }

  /deep/ .el-breadcrumb {
    display: inline-block;
  }

  header {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-bottom: 20px;
  }

  h2 {
    font-weight: 600;
    height: 60px;
    .title {
      display: inline-block;
      vertical-align: middle;
      font-size: 24px;
      color: #333;
      max-width: 60%;
      line-height: 60px;
    }
    .money {
      display: inline-block;
      vertical-align: middle;
      font-size: 30px;
      color: #f2641e;
      line-height: 46px;
      max-width: 40%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 20px;
      span {
        font-size: 14px;
        margin-left: -10px;

        &.only {
          font-size: 16px;
          margin-left: 0;
          font-weight: 400;
        }
      }
    }
  }

  h3 {
    font-size: 16px;
    color: #666;
    line-height: 30px;
    height: 30px;
    margin-bottom: 30px;
  }

  p {
    font-size: 14px;
    color: #999;
    line-height: 1.5;
  }

  .content {
    font-size: 14px;
    color: #333;
    line-height: 24px;
    min-height: 280px;
    max-height: 500px;
    width: 100%;
    overflow-y: scroll;
    /deep/ img {
      max-width: 100%;
    }
  }

  .submitBox {
    text-align: center;
    padding: 20px;
    .el-button {
      padding: 16px 60px;
      font-size: 16px;
    }
  }
}
.dialog{
  text-align: center;
  .dialog-text{
    display: block;
    color: #8493FA;
  }
  .dialog-img{
    max-width: 300px; 
    max-height: 300px
  }
}
</style>