<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-18 10:41:21
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-04 14:46:30
-->
<template>
  <div class="right_section">
    <router-link to="/userCenter/index?position=card_four" class="attend_btn" v-if="!user.jobMemo">
      <el-button type="success">完善接单意向</el-button>
    </router-link>

    <div class="hotTasks" v-if="list.length">
      <header>
        <i class="el-icon-star-off"></i>热门任务
      </header>
      <ul>
        <template v-for="(item,index) in list">
          <li v-if="index<3" :key="'taskItem'+index" @click="changeOrder(item)">
            <router-link :to="'/tasksDetail?taskId='+item.id">
              <p>
                <span class="text-ellipsis">
                  {{item.taskMoney=="费用面议"?"":item.amount}}
                  <span
                    :class="{only:item.amount==undefined||item.amount.length == 0||item.taskMoney == '费用面议'}"
                  >
                    <template v-if="item.taskMoney == '费用面议'">费用面议</template>
                    <template v-else-if="item.taskMoney == '固定价格'">/元</template>
                    <template v-else-if="item.taskMoney == '按月'">元/月</template>
                    <template v-else-if="item.taskMoney == '按周'">元/周</template>
                    <template v-else-if="item.taskMoney == '按天'">元/天</template>
                    <template v-else-if="item.taskMoney == '按时'">元/小时</template>
                  </span>
                </span>
                <span class="text-ellipsis">{{item.ecompanyJobCategoryName}}</span>
              </p>
              <p>{{item.cityName?item.cityName:"不限"}}</p>
            </router-link>
          </li>
        </template>
      </ul>
    </div>
    <a
      :href="adImg1.url"
      class="right_bottom_ad"
      target="_blank"
      v-if="adImg1.url==undefined||(adImg1.url&&(adImg1.url.startsWith('http://')||adImg1.url.startsWith('https://')))"
    >
      <img :src="adImg1.pic" />
    </a>
    <router-link class="right_bottom_ad" :to="adImg1.url" v-else>
      <img :src="adImg1.pic" />
    </router-link>

    <a
      :href="adImg2.url"
      class="right_bottom_ad"
      target="_blank"
      v-if="adImg2.url==undefined||(adImg2.url&&(adImg2.url.startsWith('http://')||adImg2.url.startsWith('https://')))"
    >
      <img :src="adImg2.pic" />
    </a>
    <router-link :to="adImg2.url" class="right_bottom_ad" v-else>
      <img :src="adImg2.pic" />
    </router-link>
  </div>
</template>

<script>
import api from "@/api/list";
import { mapGetters } from "vuex";
import { getToken } from "@/utils/auth";
export default {
  data() {
    return {
      list: [],
      adImg1: {
        url: "",
        pic: "",
      },
      adImg2: {
        url: "",
        pic: "",
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  // watch: {
  //   $route: function () {
  //     this.getList();
  //   },
  // },
  mounted() {
    //获取任务列表
    this.getList();
    if (getToken() !== "undefined" && getToken()) {
      api.getAD({}, { subUrl: "/MissionHallOne" }).then((res) => {
        if (res && res.data && res.data.length) {
          this.adImg1 = res.data[0];
          this.adImg1.pic = process.env.VUE_APP_FILE_PATH + res.data[0].pic;
        }
      });

      api.getAD({}, { subUrl: "/MissionHallTwo" }).then((res) => {
        if (res && res.data && res.data.length) {
          this.adImg2 = res.data[0];
          this.adImg2.pic = process.env.VUE_APP_FILE_PATH + res.data[0].pic;
        }
      });
    }else{
      api.getNoLoginAD({}, { subUrl: "/MissionHallOne" }).then((res) => {
        if (res && res.data && res.data.length) {
          this.adImg1 = res.data[0];
          this.adImg1.pic = process.env.VUE_APP_FILE_PATH + res.data[0].pic;
        }
      });

      api.getNoLoginAD({}, { subUrl: "/MissionHallTwo" }).then((res) => {
        if (res && res.data && res.data.length) {
          this.adImg2 = res.data[0];
          this.adImg2.pic = process.env.VUE_APP_FILE_PATH + res.data[0].pic;
        }
      });
    }
  },
  methods: {
    getList() {
      //获取任务列表
      api.hotTaskList().then((res) => {
        if (res && res.data) {
          this.list = res.data;
          if (this.$route.path == "/tasksDetail") {
            let _index = this.list.findIndex((item) => {
              return item.id == this.$route.query.taskId;
            });
            let item1 = res.data[_index];
            this.list.splice(_index, 1);
            this.list.push(item1);
          }
        }
      });
    },
    changeOrder(item1) {
      let _index = this.list.findIndex((item) => {
        return item.id == item1.id;
      });
      this.list.splice(_index, 1);
      this.list.push(item1);
    },
  },
};
</script>

<style lang="less" scoped>
.right_section {
  float: right;
  width: 290px;

  .attend_btn {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background: #81e1b7;
    /deep/ .el-button {
      width: 100%;
    }
  }

  .hotTasks {
    width: 100%;
    border-radius: 3px;
    margin-bottom: 10px;
    background: #fff;
    header {
      font-size: 18px;
      color: #333;
      line-height: 52px;
      padding: 0 18px;
      i {
        color: #81e1b7;
        margin-right: 12px;
      }
    }

    ul {
      li {
        border-top: 1px solid #f5f5f5;
        a {
          display: block;
          padding: 12px 20px;
          p {
            font-size: 12px;
            color: #666;
            line-height: 30px;
            span {
              font-size: 16px;
              color: #333;
              max-width: 8em;
              &:first-child {
                color: #f2641e;
                float: right;
                span {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  a.right_bottom_ad,
  img {
    display: block;
    width: 292px;
    height: auto;
    overflow: hidden;
    border-radius: 6px;
    margin-bottom: 10px;
  }
}
</style>