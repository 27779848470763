<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-14 09:43:12
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-09 09:32:20
-->
<template>
  <div>
    <template v-if="imageArr.length">
      <div class="previewItem" v-for="(item,index) in imageArr" :key="'image1'+index">
        <el-image :src="item" :preview-src-list="imageArr"></el-image>
        <div class="mask" @click="handleRemove(index,1)" v-if="!readOnly">
          <i class="el-icon-close"></i>
        </div>
      </div>
    </template>
    <template v-if="PDFArr.length">
      <div class="previewItem" v-for="(item,index) in PDFArr" :key="'pdf'+index">
        <p class="pdf text-ellipsis" @click="handlePdfView(item)">{{item}}</p>
        <div class="mask" @click="handleRemove(index,0)" v-if="!readOnly">
          <i class="el-icon-close"></i>
        </div>
      </div>
    </template>
    <el-upload
      v-if="!readOnly && limit > imageArr.length+PDFArr.length"
      class="uploader_section"
      id="uploader_section"
      :subUrl="subUrl"
      list-type="picture-card"
      :action="action"
      :accept="accept"
      :limit="limit"
      :show-file-list="false"
      :headers="headers"
      :on-success="handSuccess"
      :before-upload="beforeUpload"
      :on-error="handError"
    >
      <i class="el-icon-plus"></i>
      <p class="text">{{text}}</p>
      <p class="limit">格式：{{typeLimit}}</p>
    </el-upload>
  </div>
</template>

<script>
import { apiList } from "@/api/list";
export default {
  name: "uploadFile",
  props: {
    headers: {
      type: Object,
      default: function () {
        return {
          Authorization: this.$store.getters.token,
        };
      },
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 1,
    },
    text: {
      type: String,
      default: "点击上传文件",
    },
    typeLimit: {
      type: String,
      default: "jpg,png,pdf",
    },
    fileList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    subUrl: {
      type: String,
      default: "pcUploader",
    },
    accept: {
      type: String,
      default: "image/*, .pdf",
    },
  },
  data() {
    return {
      imageArr: [],
      PDFArr: [],
      loading: null,
      loadingCount: 0,
    };
  },
  computed: {
    action: function () {
      return process.env.VUE_APP_BASE_API + apiList.uploadUrl + this.subUrl;
    },
  },
  watch: {
    fileList: {
      handler: function (val) {
        this.PDFArr = [];
        this.imageArr = [];
        if (val && val.length) {
          val.forEach((item, index) => {
            if (item.toUpperCase().endsWith(".PDF")) {
              this.PDFArr.push(item);
            } else {
              this.imageArr.push(item);
            }
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    beforeUpload(file) {
      if (this.loadingCount == 0) {
        this.loading = this.showLoading();
      }
      this.loadingCount++;
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message({
          showClose: true,
          message: "上传图片大小不能超过2MB!",
          type: "error",
        });
      }
      return isLt2M;
    },

    //删除文件
    handleRemove(index, type) {
      if (type == 1) {
        this.imageArr.splice(index, 1);
      } else {
        this.PDFArr.splice(index, 1);
      }
      this.$emit("setFile", this.imageArr.concat(this.PDFArr));
      this.$message({
        showClose: true,
        message: "文件删除成功！",
        type: "success",
      });
    },
    //文件超过限制张数
    handExceed() {
      this.$message({
        showClose: true,
        message: "文件上传张数超出限制，请删除后再上传~",
        type: "error",
      });
    },
    //文件上传成功
    handSuccess(response) {
      let temp = process.env.VUE_APP_FILE_PATH + response.data;
      if (response.data.toUpperCase().endsWith(".PDF")) {
        this.PDFArr.push(temp);
      } else {
        this.imageArr.push(temp);
      }
      this.$emit("setFile", this.imageArr.concat(this.PDFArr));
      this.$message({
        showClose: true,
        message: "文件上传成功！",
        type: "success",
      });
      this.closeLoading(this.loading);
    },
    //文件上传失败
    handError(err) {
      this.$message({
        showClose: true,
        message: err,
        type: "error",
      });
      this.closeLoading(this.loading);
    },
    showLoading() {
      let loading = this.$loading({
        lock: true,
        target: document.querySelector("#uploader_section"),
        fullscreen: false,
      });
      return loading;
    },
    closeLoading(loading) {
      this.loadingCount--;
      if (this.loadingCount == 0) {
        loading.close();
      }
    },
    handlePdfView(item) {
      let routeData = this.$router.resolve({
        path: "/pdfPreview",
        query: {
          fileURL: item,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.uploader_section {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 12px;
  /deep/.el-loading-mask {
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
  }
  /deep/ .el-upload--picture-card {
    border-color: #d4d5da;
    background-color: #eff0f6;
    line-height: 20px;
    padding: 20px 0;
    border-radius: 0;
    i {
      font-size: 60px;
      color: #d2d3d8;
      margin-bottom: 12px;
    }
    .text {
      color: #666;
      font-size: 14px;
    }
    .limit {
      color: #999;
      font-size: 12px;
    }
  }
}

.previewItem {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 148px;
  height: 148px;
  border: 1px solid #d4d5da;
  margin: 0 8px 12px 0;
  overflow: hidden;
  background-color: #eff0f6;

  .pdf {
    background: url(../../assets/pdf.png) center top no-repeat;
    color: #666;
    font-size: 14px;
    height: 110px;
    padding: 84px 10px 0;
    margin: 20px 0;
  }

  /deep/ img {
    width: 148px;
    height: 148px;
    object-fit: contain;
  }

  .mask {
    position: absolute;
    right: -15px;
    top: -6px;
    width: 40px;
    height: 24px;
    background: #ff3838;
    text-align: center;
    transform: rotate(45deg);
    box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
    /deep/ i {
      position: relative;
      top: -5px;
      font-size: 12px;
      margin-top: 15px;
      transform: rotate(-45deg);
      color: #fff;
    }
  }
}
</style>
